var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lead-landing",class:[_vm.service, ("step_" + _vm.step)]},[_c('div',{staticClass:"main-container",class:[_vm.service, ("step_" + _vm.step)]},[_c('img',{class:( _obj = {logo: true}, _obj[_vm.service] = true, _obj ),attrs:{"src":_vm.getImgSrc}}),(_vm.step === 1 && _vm.service === 'bh')?_c('div',{class:( _obj$1 = {}, _obj$1[_vm.service] = true, _obj$1 ),staticStyle:{"margin-top":"30px"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"custom-button",on:{"click":function($event){return _vm.nextStep('video')}}},[_vm._v("Contact me")]),_c('button',{staticClass:"custom-button",on:{"click":function($event){return _vm.nextStep('details')}}},[_vm._v("More details")]),_c('button',{staticClass:"custom-button",on:{"click":function($event){return _vm.nextStep('tour')}}},[_vm._v("Schedule a tour")])])]):_vm._e(),(_vm.step === 1 && _vm.service === 'bmw')?_c('div',{class:( _obj$2 = {}, _obj$2[_vm.service] = true, _obj$2 ),staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"custom-button",on:{"click":function($event){return _vm.nextStep('video')}}},[_vm._v("Complete Details")]),_c('button',{staticClass:"custom-button",on:{"click":function($event){return _vm.nextStep('details')}}},[_vm._v("Click here for extra discount")])])]):_vm._e(),(_vm.step === 2)?_c('div',{class:( _obj$3 = {}, _obj$3[_vm.service] = true, _obj$3 )},[_c('div',{staticClass:"big-message",domProps:{"textContent":_vm._s(_vm.getBigMessageText)}}),(!_vm.leadRequestDone)?_c('labeled-cleave',{class:['phone-input', _vm.inputEmpty && 'empty', _vm.error && 'error'],attrs:{"options":{
                            numericOnly: true,
                            blocks:[3, 3, 4],
                            delimiters: [' ', '-'],
                            delimiterLazyShow: true,
                        },"inputmode":"numeric","placeholder":"(000) 000-0000"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1):_vm._e(),(!_vm.leadRequestDone && _vm.step === 2)?_c('el-button',{class:( _obj$4 = {'submit-btn':true}, _obj$4[_vm.service] = true, _obj$4 ),attrs:{"loading":_vm.async,"disabled":!_vm.phoneValid},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-container"},[_c('video',{attrs:{"controls":"","width":"100%","playsinline":""}},[_c('source',{attrs:{"src":"https://rtxt-storage.texttelligent.com/content/community_expert_berkshire_hathaway.mp4","type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]),_c('h4',[_vm._v("REAL STORIES ABOUT REAL ESTATE")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-container"},[_c('h2',[_vm._v("Your Community Expert")]),_c('div',[_vm._v(" Selling a home is easy—if you have the right agent. Home doesn’t end at the front door but there’s a lot more involved in a successful home sale than a fantastic neighborhood. It takes a network agent with the local-market expertise to make that community shine for every prospective buyer. ")])])}]

export { render, staticRenderFns }