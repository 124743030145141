<template>
    <div class="lead-landing" :class="[service, `step_${step}`]">
        <div class="main-container" :class="[service, `step_${step}`]">
            <img :class="{logo: true, [service]: true}" :src="getImgSrc"/>

            <div v-if="step === 1 && service === 'bh'" style="margin-top: 30px" :class="{[service]: true}">
                <div class="video-container">
                    <video controls width="100%" playsinline>
                        <source
                            src="https://rtxt-storage.texttelligent.com/content/community_expert_berkshire_hathaway.mp4"
                            type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    <h4>REAL STORIES ABOUT REAL ESTATE</h4>
                </div>


                <div class="text-container">
                    <h2>Your Community Expert</h2>
                    <div>
                        Selling a home is easy—if you have the right agent. Home doesn’t end at the front door but
                        there’s a lot more involved in a successful home sale than a fantastic neighborhood. It takes a
                        network agent with the local-market expertise to make that community shine for every prospective
                        buyer.
                    </div>
                </div>

                <div class="button-container">
                    <button class="custom-button" @click="nextStep('video')">Contact me</button>
                    <button class="custom-button" @click="nextStep('details')">More details</button>
                    <button class="custom-button" @click="nextStep('tour')">Schedule a tour</button>
                </div>
            </div>

            <div v-if="step === 1 && service === 'bmw'" style="margin-top: 30px" :class="{[service]: true}">

                <div class="button-container">
                    <button class="custom-button" @click="nextStep('video')">Complete Details</button>
                    <button class="custom-button" @click="nextStep('details')">Click here for extra discount</button>
                </div>
            </div>


            <div v-if="step === 2" :class="{[service]: true}">
                <div class="big-message" v-text="getBigMessageText"/>

                <labeled-cleave :class="['phone-input', inputEmpty && 'empty', error && 'error']"
                                v-if="!leadRequestDone"
                                :options="{
                                numericOnly: true,
                                blocks:[3, 3, 4],
                                delimiters: [' ', '-'],
                                delimiterLazyShow: true,
                            }"
                                inputmode="numeric"
                                placeholder="(000) 000-0000"
                                v-model="phoneNumber"/>

                <div class="error-message" v-if="error">{{ error }}</div>
            </div>

            <el-button :loading="async"
                       :disabled="!phoneValid"
                       :class="{'submit-btn':true, [service]: true}"
                       v-if="!leadRequestDone && step === 2"
                       @click="submit">
                {{ buttonText }}
            </el-button>

        </div>
    </div>
</template>

<script>
import LabeledCleave from '@/components/LabeledCleave/LabeledCleave';
import {fetchPropertyByKeywordName, fetchPropertyByShortId} from '@/api/properties/properties';
import {sendLeadDetailsWithProperty} from '@/api/leads/leads';
import ReTextLogo from '../../assets/img/logoRE_dark_black.svg';
import TexttelligentLogo from '../../assets/img/texttelligent_logo.svg';
import BerkshireHathawayLogo from '../../assets/img/primary_blk.svg';
import IrvineBMWLogo from '../../assets/img/Irvine_BMW_logo_white.svg';

export default {
    name: 'LeadLanding',
    components: {LabeledCleave},
    data() {
        return {
            async: false,
            phoneNumber: '',
            property: null,
            leadRequestDone: false,
            error: '',
            service: this.$route.query.demo,
            step: ['bh', 'bmw'].includes(this.$route.query.demo) ? 1 : 2
        };
    },
    computed: {
        lightMode() {
            return this.$store.state.preferences.isLightMode;
        },
        inputEmpty() {
            return this.phoneNumber.length === 0;
        },
        phoneValid() {
            return this.phoneNumber.length === 10;
        },
        buttonText() {
            switch (this.service) {
                case 'bh':
                    return this.leadRequestDone ? 'Requested Details' : 'Send';
                case 'bmw':
                    return this.leadRequestDone ? 'Requested Details' : 'Send';
                case 'retext':
                default:
                    return this.leadRequestDone ? 'Requested Details' : 'SEND ME DETAILS';
            }
        },
        getImgSrc() {
            switch (this.service) {
                case 'bmw':
                    return IrvineBMWLogo;
                case 'retext':
                    return ReTextLogo;
                case 'bh':
                    return BerkshireHathawayLogo;
                default:
                    return TexttelligentLogo;
            }
        },
        getBigMessageText() {

            if (this.leadRequestDone) {
                return 'Thank you. Requested information will be sent shortly.';
            }

            switch (this.service) {
                case 'bmw':
                    return 'Send to phone';
                case 'retext':
                    return 'Property details you requested will be sent to the number you enter below.';
                case 'bh':
                    return 'Please enter your phone number below.';
                default:
                    return 'Details you requested will be sent to the number you enter below.';
            }
        },
    },

    methods: {
        nextStep() {
            this.step = 2;
        },
        submit() {
            this.error = null;
            if (this.leadRequestDone) {
                return window.open(this.property?.url);
            } else if (this.phoneValid && this.property?.id) {

                this.async = true;

                let phone = `+1${this.phoneNumber}`;

                sendLeadDetailsWithProperty({lead_phone: phone, property_id: this.property?.id})
                    .then(() => this.leadRequestDone = true)
                    .catch(() => this.error = 'An error has occurred. Please check your phone number.')
                    .finally(() => this.async = false);
            }
        },
    },

    async mounted() {
        const {keyword, shortId} = this.$route.params;

        let r = null;

        if (shortId) {
            r = await fetchPropertyByShortId(shortId);
        } else if (keyword) {
            r = await fetchPropertyByKeywordName(keyword);
        }

        if (r) {
            this.property = r;
        }
    },
};
</script>

<style scoped lang="scss">
.lead-landing {
    @font-face {
        font-family: 'ProximaNova-Light';
        src: url('../../assets/fonts/ProximaNova-Light.ttf') format('truetype');

        font-weight: auto;
        font-style: normal;
    }
    @font-face {
        font-family: 'GeorgiaPro-Bold';
        src: url('../../assets/fonts/GeorgiaPro-Bold.ttf') format('truetype');
    }
    @font-face {
        font-family: 'GeorgiaPro-SemiBold';
        src: url('../../assets/fonts/GeorgiaPro-SemiBold.ttf') format('truetype');
    }

    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;

    &.bmw {
        background-image: url("../../assets/img/Irvine_BMW_landing_01.jpg");
        background-size: contain;
        background-repeat: repeat-x;
        background-position: center;

        &.step_2 {
            background-image: url("../../assets/img/Irvine_BMW_landing_02.jpg");
        }
    }

    .main-container {
        display: flex;
        flex-direction: column;
        max-width: 300px;

        &.bmw {
            max-width: unset;
            width: 100%;
            justify-content: space-between;
        }

        .logo {
            width: 160px;

            &.bmw {
                width: 90%;
            }

            &.bh {
                color: #602853;
            }
        }

        .big-message {
            font-size: 32px;
            font-weight: 800;
            color: #28032E;
            line-height: 34px;
            margin: 30px 0;
        }

        .small-message {
            color: rgba(#28032E, 0.5);
            font-size: 18px;
            font-weight: 500;
        }

        &::v-deep .input-container {
            input {
                color: #28032E;
                font-weight: 800;
                font-size: 32px;
                border: none;
                padding-left: 0;
                padding-right: 0;
                border-bottom: 1px solid rgba(40, 3, 46, 0.3);
                border-radius: 0;

                &:focus, &:hover {
                    border: none;
                    border-bottom: 1px solid rgba(40, 3, 46, 0.3);
                }

                &::placeholder {
                    color: rgba(40, 3, 46, 0.3);
                }
            }
        }

        .phone-input.error {
            &::v-deep {
                input {
                    border-bottom-color: #FA5D5D;
                }
            }
        }

        .error-message {
            color: #FA5D5D;
        }

        .submit-btn {
            padding: 15px 35px;
            border-radius: 30px;
            outline: none;
            font-weight: bold;
            cursor: pointer;
            margin-top: 35px;
            color: #ffffff;
            background-color: #13A68A;
            border: 1px solid #13A68A;
            width: 100%;

            &:hover {
                background-color: #05c696;
                border: 1px solid #05c696
            }

            &:disabled {
                opacity: 0.2;

                &:hover {
                    background-color: #13A68A;
                    border: 1px solid #13A68A;
                    color: #ffffff;
                }
            }


            &.bmw {
                font-size: 20px;

                padding: 15px 20px;
                border: 1px solid #FFFFFF; /* Colored border */
                background-color: #FFFFFF; /* Transparent background */
                color: #007AFF; /* Button text color */
                cursor: pointer;
                border-radius: 10px; /* 5px border radius */


                &:disabled {
                    &:hover {
                        background-color: #FFFFFF;
                        border: 1px solid #FFFFFF;
                        color: #007AFF;
                    }
                }
            }

        }

        .bmw {

            .big-message {
                text-align: center;
                color: #FFFFFF;
            }

            &::v-deep .input-container {
                input {
                    color: #FFFFFF;
                    font-weight: 800;
                    font-size: 32px;
                    border: none;
                    padding-left: 0;
                    padding-right: 0;
                    border-bottom: 1px solid #FFFFFF;
                    border-radius: 0;
                    text-align: center;

                    &:focus, &:hover {
                        border: none;
                        border-bottom: 1px solid #FFFFFF;
                    }

                    &::placeholder {
                        color: #FFFFFF;
                    }
                }
            }

            .button-container {
                display: flex;
                flex-direction: column; /* Change to column layout */
                align-items: stretch; /* Stretch buttons to full width */
                margin-top: 20px;

                .custom-button {
                    font-size: 20px;

                    flex: 1; /* Allow buttons to take up equal space */
                    padding: 15px 20px;
                    border: 1px solid #FFFFFF; /* Colored border */
                    background-color: #FFFFFF; /* Transparent background */
                    color: #007AFF; /* Button text color */
                    cursor: pointer;
                    border-radius: 10px; /* 5px border radius */

                    margin-top: 15px; /* Add some spacing between buttons */
                }
            }
        }

        .bh {
            text-align: center;

            .big-message {
                color: #602853;
                font-size: 26px;
                font-family: GeorgiaPro-Bold;
            }

            .button-container {
                display: flex;
                flex-direction: column; /* Change to column layout */
                align-items: stretch; /* Stretch buttons to full width */
                margin-top: 20px;

                .custom-button {
                    font-family: 'GeorgiaPro-SemiBold', serif !important;
                    font-size: 20px;

                    flex: 1; /* Allow buttons to take up equal space */
                    padding: 15px 20px;
                    border: 2px solid #602853; /* Colored border */
                    background-color: transparent; /* Transparent background */
                    color: #602853; /* Button text color */
                    cursor: pointer;
                    border-radius: 10px; /* 5px border radius */

                    margin-top: 15px; /* Add some spacing between buttons */
                }
            }

            .video-container {
                font-family: ProximaNova-Light, sans-serif !important;

                video {
                    border-radius: 15px;
                }

                h4 {
                    margin-top: 8px;
                    text-align: left;
                }
            }

            .text-container {
                font-family: ProximaNova-Light, serif !important;
                font-size: 14px;
                line-height: 21.5px;
                text-align: left;

                h2 {
                    font-family: GeorgiaPro-Bold, serif !important;
                    margin-bottom: 10px;
                    font-size: 22px;
                    font-weight: bold;
                    color: #602853;
                }
            }


            &::v-deep .input-container {
                input {
                    color: #602853;
                    font-weight: 800;
                    font-size: 32px;
                    border: none;
                    padding-left: 0;
                    padding-right: 0;
                    border-bottom: 1px solid rgba(96, 40, 83, 0.3);
                    border-radius: 0;
                    text-align: center;

                    &:focus, &:hover {
                        border: none;
                        border-bottom: 1px solid rgba(96, 40, 83, 0.3);
                    }

                    &::placeholder {
                        color: rgba(96, 40, 83, 0.3);
                    }
                }
            }

            .submit-btn {
                background: #602853;
                color: #FFFFFF;
                font-size: 20px;
                border-radius: 10px;
                flex: 1;
                padding: 15px 20px;
                border: 1px solid #602853;
                cursor: pointer;
                text-transform: none;

                span {
                    font-family: 'GeorgiaPro-SemiBold', sans-serif !important;
                }
            }
        }
    }
}
</style>
